import React from "react";

import PropTypes from "prop-types";
import queryString from "query-string";
import bindActionCreators from "redux/lib/bindActionCreators";
import moment from "moment";
import connect from "react-redux/lib/connect/connect";
import cookie from "react-cookies";

import * as ActivityDucks from "ducks/activities/activity";
import * as RouteDucks from "ducks/routes";
import * as PayrollDucks from "ducks/payrolls/payroll";
import * as TimecardDucks from "ducks/timecards/timecard";
import * as BasicSettingDucks from "ducks/vendors/basicSetting";
import * as DailyTimecardDucks from "ducks/timecards/dailyTimecard";
import * as AdjustmentTypesDucks from "ducks/vendors/adjustmentTypes";
import * as AdjustmentTimecardDucks from "ducks/timecards/adjustmentTimecard";
import * as DepartmentDucks from "ducks/vendors/department";
import * as TeamDucks from "ducks/vendors/team";
import * as UserDucks from "ducks/accounts/user";

import { toast } from "react-toastify";
import { UncontrolledTooltip } from "reactstrap";

import Button from "reactstrap/lib/Button";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";

import { DECIMAL_VIEW_OPTIONS } from "constants/timecards";

import ButtonGroupAtom from "atoms/ButtonGroup";
import FilterDropdownComponent from "components/common/FilterDropdown";
import AdjustmentHourFormModalComponent from "components/timecards/dailyTimecardList/AdjustmentHourFormModalComponent";
import CustomTimecardFilterFormComponent from "components/timecards/customList/CustomTimecardFilterForm";
import ListEditIcon from "components/timecards/customDailyTimecardList/list-edit.png";
import ListIcon from "components/timecards/customDailyTimecardList/list.png";
import styles from "./styles.module.scss";
import showHideIcon from "components/timecards/customDailyTimecardList/showHideIcon.png";
import activityProgressIcon from "components/timecards/customDailyTimecardList/activityProgressIcon.png";
import QuestionMarkFilled from "components/timecards/customDailyTimecardList/QuestionMarkFilled.png"
import LayerAtom from "atoms/Layer";
import StorageGateway from "lib/storage-gateway";
import FormikMultiSelectAtom from "atoms/FormikMultiselectDropdown";


import InfoBlue1 from "components/timecards/customDailyTimecardList/InfoBlue1.png";
import InfoRed1 from "components/timecards/customDailyTimecardList/InfoRed1.png"

/**
 * DailyTimecardListPage -> DailyTimecardFilterSection
 *
 * Components:
 *    - Add Missing Activity Link
 *
 * State:
 *    None
 *
 * Actions:
 *    None
 */

let company_id = "";
let nre_token = "";
let nre_company_id = "";
let userID = "";
let team_ids = [];
let dept_ids = [];
let null_depts = [];
let combinedDepts = [];
class DailyTimecardFilterSection extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.onDecimalHHMMToggle = this.onDecimalHHMMToggle.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.update = this.update.bind(this);
    this.goTo = this.goTo.bind(this);
    this.fetchInProgressActivityData = this.fetchInProgressActivityData.bind(
      this
    );
    this.onDepartmentSelect = this.onDepartmentSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      isOpen: false,
      isActivityInProgressLoader: false,
      isModalOpen: false,
      attributeToggler: false,
      employeeArrLength:0,
      currentIndex:0,
      loadingClearNosql: false,
      toggleBroadcastMessage: false,
      selectedDepts: [],
      selectedTeams:[],
    };
    this.onTeamSelect = this.onTeamSelect.bind(this);
    this.onPreviousAndNextClick =this.onPreviousAndNextClick.bind(this)
    this.onEmployeeClickRedirect =this.onEmployeeClickRedirect.bind(this)
    this.clearNosql =this.clearNosql.bind(this)
    this.eventListnerFunction = this.eventListnerFunction.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentWillMount() {
    const {location: { query },} = this.props;
    const cookieDepartmentId = cookie.load('departmentId');
    const cookieTeamId = cookie.load('teamId');
    const nullDept = cookie.load("nullDept")|| [];
 
    let department_ids = cookieDepartmentId ? cookieDepartmentId : query?.department_ids;
    let teams_ids = cookieTeamId ? cookieTeamId : query?.team_ids;
   
    let departmentIdsArray;
    let teamIdsArray;

    if(department_ids){
     
      if(Array.isArray(department_ids)){
        departmentIdsArray= department_ids;
      } 
      else{
        departmentIdsArray= [department_ids]
      }
    }
    else{
      departmentIdsArray=[];
    }
    const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));

    if(teams_ids){
      if(Array.isArray(teams_ids)){
        teamIdsArray= teams_ids;
      } 
      else{
        teamIdsArray= [teams_ids]
      }
    }
    else{
      teamIdsArray=[];
    }
    const teamIdsIntegers = teamIdsArray.map(id => parseInt(id, 10));
    let combinedDepartments = [...(departmentIdsIntegers || []), ...(nullDept || [])];

    this.setState({selectedDepts: combinedDepartments?.length ? combinedDepartments : [],
       selectedTeams:teamIdsIntegers?.length?teamIdsIntegers:[]})
       this.combinedDepts = combinedDepartments?.length ? combinedDepartments : [];
      this.team_ids = teamIdsIntegers?.length?teamIdsIntegers:[];

    Promise.all([
      this.props.DepartmentDucks.getAllDepartments({ paginate: false }),
      this.props.TeamDucks.getAllTeams({
        paginate: false,
        department_ids: JSON.stringify(departmentIdsIntegers),
      }),
    ]).then(() => {
      if (this.props.setWeekAndDate) {
        this.props.setWeekAndDate(query);
      }
    });
  }

  eventListnerFunction(event) {
    const data =
      event.data && typeof event.data !== "object" && JSON.parse(event.data);
    const message = data?.message;

  if (message === "FAILED_TOAST") {
      toast.error(data?.content?.toastMessage);
    } else if (message === "SUCCESS_TOAST") {
      toast.success(data?.content?.toastMessage);
    } else if (message === "BROADCAST_SUCCESS") {
      toast.success(data?.content?.toastMessage);
    }
  }

  componentDidMount() {
    window.addEventListener("message", this.eventListnerFunction, false);
    company_id = StorageGateway.get("company_id");
    nre_token = StorageGateway.get("graniteToken");
    nre_company_id = StorageGateway.get("nre_company_id");
    userID = StorageGateway.get("userId");
  }
  componentWillUnmount() {
    window.removeEventListener("message", this.eventListnerFunction);
  }


  componentDidUpdate(prevProps){
    const { location: { query }} = this.props;
    const cookieDepartmentId = cookie.load('departmentId');
    const cookieTeamId = cookie.load('teamId');
    const nullDept = cookie.load("nullDept")|| [];

    let department_ids = cookieDepartmentId ? cookieDepartmentId : query?.department_ids;
    let teams_ids = cookieTeamId ? cookieTeamId : query?.team_ids;
   

    
    if ((prevProps.departmentAll !== this.props.departmentAll) && query && !query.department_ids?.length) {
  
    let departmentIdsArray;
      if(department_ids){
        if(Array.isArray(department_ids)){
          departmentIdsArray= department_ids;
        } 
        else{
          departmentIdsArray= [department_ids]
        }
      }
      else{
        departmentIdsArray=[];
      }
      const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));
      let combinedDepartments = [...(departmentIdsIntegers || []), ...(nullDept || [])];

    this.setState({ selectedDepts: combinedDepartments?.length ? combinedDepartments : [] });
   this.combinedDepts = combinedDepartments?.length ? combinedDepartments : [];
  
    }
  
   
    if ((prevProps.teamAll !== this.props.teamAll) && query && !query.team_ids?.length) {
    
    let teamIdsArray;
  
      if(teams_ids){
        if(Array.isArray(teams_ids)){
          teamIdsArray= teams_ids;
        } 
        else{
          teamIdsArray= [teams_ids]
        }
      }
      else{
        teamIdsArray=[];
      }
      const teamIdsIntegers = teamIdsArray.map(id => parseInt(id, 10));
      this.setState({ selectedTeams:teamIdsIntegers?.length?teamIdsIntegers:[]});
   this.team_ids = teamIdsIntegers?.length?teamIdsIntegers:[];

    }
  
  
  }



  componentWillReceiveProps(nextProps) {
    const {
      location: { query: nextQuery },
    } = nextProps;
    const {
      location: { query: prevQuery },
      timecardList
    } = this.props;
    const cookieDepartmentId = cookie.load('departmentId');
  const cookieTeamId = cookie.load('teamId');
  const nullDept = cookie.load("nullDept") || [];

    let departmentIdsArray;
    if(nextQuery.department_ids){
      if(Array.isArray(nextQuery.department_ids)){
        departmentIdsArray= nextQuery.department_ids;
      } 
      else{
        departmentIdsArray= [nextQuery.department_ids]
      }
    }
    else{
      departmentIdsArray=[];
    }
    const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));
 let combinedDepartments = [...(departmentIdsIntegers || []), ...(nullDept || [])];
    this.setState({ selectedDepts: combinedDepartments?.length ? combinedDepartments : [] });
 this.combinedDepts = combinedDepartments?.length ? combinedDepartments : [];
  
    if (
      prevQuery.department_ids !== nextQuery.department_ids &&
      nextQuery.department_ids
    ) {
      this.props.TeamDucks.getAllTeams({
        paginate: false,
        department_ids: JSON.stringify(departmentIdsIntegers),
      }).then(() => {
        if (this.props.setWeekAndDate) {
          this.props.setWeekAndDate(nextQuery);
        }
      });
    }
    if(timecardList?.results && nextQuery)
    {
      var index = timecardList?.results.map((o) =>o.employee__id).indexOf(parseInt(nextQuery?.employee_id));
      this.setState({currentIndex:index!==-1?index:0,employeeArrLength:timecardList?.count ?? 0 })
    }
  }

  // handleClick() {
  //   this.setState({selectedDepts:this.dept_ids})
  //   this.setState({selectedTeams:this.team_ids})
  //   if((this.dept_ids?.length>1) || (this.dept_ids?.length===0 && this.team_ids)){
  //     this.handleChange('custom')
  //   }
  //   setTimeout(() => {
  //   const { router: { history } } = this.context;
  //   const { location: { pathname, query } } = this.props;
  //   const newQuery = { ...query };
  //   history.push({ pathname,
  //       search: queryString.stringify({...newQuery,
  //         department_ids: this.dept_ids,
  //         team_ids: this.team_ids,
  //     })});
  //   }, 1000);
  // };


handleClick() {
  this.combinedDepts = [...this.dept_ids, ...this.null_depts];
  cookie.save('departmentId', this.dept_ids, { path: '/' });
      cookie.save('teamId', this.team_ids,  { path: '/'});
      cookie.save('nullDept', this.null_depts,  { path: '/'});
  this.setState(
    { selectedDepts: this.combinedDepts, selectedTeams: this.team_ids },
    async () => {
      if ((this.dept_ids?.length > 1) || (this.dept_ids?.length === 0 && this.team_ids)) {
        await this.handleChange('custom');
      }

      const { router: { history } } = this.context;
      const { location: { pathname, query } } = this.props;
      const newQuery = { ...query };
      history.push({
        pathname,
        search: queryString.stringify({
          ...newQuery,
          department_ids: this.dept_ids.filter((item)=>item !== "true"),
          team_ids: this.team_ids,
          null_department:this.null_depts[0]?this.null_depts[0]:undefined,
        }),
      });
    }
  );
}

 
  onTeamSelect(data) {
    this.team_ids = data.map((item) => item.id);
   
    cookie.save('teamId', team_ids,  { path: '/'});
    // history.push({ pathname,
    //   search: queryString.stringify({...newQuery,
    //     team_ids: team_ids,
    // })});
  }

  onDepartmentSelect(data) {
    this.null_depts = data.filter((item)=>item.id ==="true").map((item) => item.id);
    this.dept_ids = data.filter((item)=>item.id !=="true").map((item) => item.id);
    const { location: { pathname, query } } = this.props;
    // if (query.department_id === id || +query.department_id === id) return true;
    delete query.department_id;
    delete query.team_ids;
    if(query.payroll_id !== 'custom' ){
    delete query.payroll_id;
    }

    this.props.TeamDucks.getAllTeams({ department_ids: JSON.stringify(this.dept_ids), paginate: false })
    cookie.save("departmentId", this.dept_ids, { path: "/" });
    cookie.remove("teamId", { path: "/" });
    if (this.dept_ids?.length === 0) {
      this.setState({selectedTeams:[]});
      this.team_ids = [];
    }
  }
  
  onPreviousAndNextClick(buttonType)
  {
    const {timecardList} =this.props
    const {currentIndex,employeeArrLength} =this.state
    if(buttonType==='previous' && (currentIndex-1 ) >=0)
    {
       this.setState({currentIndex:currentIndex-1})
       this.onEmployeeClickRedirect(timecardList?.results[currentIndex-1])
    }else if(buttonType==='next' && ((currentIndex+1 ) <= (employeeArrLength-1)))
    {
      this.setState({currentIndex:currentIndex+1})
      this.onEmployeeClickRedirect(timecardList?.results[currentIndex+1])
    }
  }

  onEmployeeClickRedirect(emp){
    const { location: { pathname, query }} = this.props;
    const {router: {history}} = this.context;
    const { departmentId, teamId} = this.state;
    const showEmployeeCookie = cookie.load('isShowEmployeeListCookie') && cookie.load('isShowEmployeeListCookie') == 'true' ? true : false;
    history.push({
      pathname,
      search: queryString.stringify({
        custom: true,
        first_name: emp.employee__user__first_name,
        last_name: emp.employee__user__last_name,
        employee_id: emp.employee__id,
        emp_dept_id: emp.employee__department_id,
        timecard_decimal_view: true,
        payroll_id: query.payroll_id,
        admin: pathname.indexOf('dailyV0') >-1 ? null : true,
        beta: pathname.indexOf('dailyV0') >-1 ? null : true,
        show_employee_list: showEmployeeCookie,
        start_date: query.start_date,
        end_date: query.end_date,
        department_ids: query.department_ids,
        team_id: teamId ,
        empployee_dept_name: emp.employee__department__name,
        employee_team_name: emp.employee__team__name,
        employee_id_show: emp.employee__employee_id,
        employee_title: emp.employee__title,
      })
    })
  }
  onDecimalHHMMToggle(val) {
    // const { router: { history } } = this.context;
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    const {
      location: { pathname, query },
      userTimecardFilters,
      basicSettingDetail,
    } = this.props;

    let departmentIdsArray;
    if(query?.department_ids){
     
      if(Array.isArray(query?.department_ids)){
        departmentIdsArray= query?.department_ids;
      } 
      else{
        departmentIdsArray= [query?.department_ids]
      }
    }
    else{
      departmentIdsArray=[];
    }
    const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));

    const { page, parent, ...rest } = query;
    const preferenceObj = {
      preference_type: "user_preference_timecards",
      data: {
        manual_correction: query.manual_correction == "true" ? true : false,
        timecard_decimal_view: val,
        // (query.timecard_decimal_view == "true" || query.timecard_decimal_view == true) ? true : false,
        show_extra_info: query.show_extra_info == "true" ? true : false,
        ...rest,
        department_ids: departmentIdsIntegers
      },
    };
    preferenceObj.data.timecard_decimal_view = val;
    return this.props.TimecardDucks.updateUserFilters(preferenceObj).then(
      () => {
        // this.props.TimecardDucks.getUserFilters({
        //   preference_type: "user_preference_timecards",
        // });
        if (this.props.active === "old") {


          let departmentIdsArray;
          let teamIdsArray;
          if(rest?.department_ids){
           
            if(Array.isArray(rest?.department_ids)){
              departmentIdsArray= rest?.department_ids;
            } 
            else{
              departmentIdsArray= [rest?.department_ids]
            }
          }
          else{
            departmentIdsArray=[];
          }
          const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));
      
          if(rest?.team_ids){
            if(Array.isArray(rest?.team_ids)){
              teamIdsArray= rest?.team_ids;
            } 
            else{
              teamIdsArray= [rest?.team_ids]
            }
          }
          else{
            teamIdsArray=[];
          }
          const teamIdsIntegers = teamIdsArray.map(id => parseInt(id, 10));



          this.props.DailyTimecardDucks.getCustomDailyTimecardsV2({
            ...rest,
            id: params.id,
            paginate: false,
            admin: true,
            department_ids: JSON.stringify(departmentIdsIntegers),
            team_ids: JSON.stringify(teamIdsIntegers),
          });
        } else {
          let departmentIdsArray;
    let teamIdsArray;
    if(rest?.department_ids){
     
      if(Array.isArray(rest?.department_ids)){
        departmentIdsArray= rest?.department_ids;
      } 
      else{
        departmentIdsArray= [rest?.department_ids]
      }
    }
    else{
      departmentIdsArray=[];
    }
    const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));

    if(rest?.team_ids){
      if(Array.isArray(rest?.team_ids)){
        teamIdsArray= rest?.team_ids;
      } 
      else{
        teamIdsArray= [rest?.team_ids]
      }
    }
    else{
      teamIdsArray=[];
    }
    const teamIdsIntegers = teamIdsArray.map(id => parseInt(id, 10));
          this.props.DailyTimecardDucks.getCustomDailyTimecards({
            ...rest,
            id: params.id,
            paginate: false,
            admin: true,
            beta: "true",
            department_ids: JSON.stringify(departmentIdsIntegers),
            team_ids: JSON.stringify(teamIdsIntegers),
          });
        }
      }
    );
  }

  toggleModal() {
    this.setState({ isModalOpen: !this.state.isModalOpen });
    // this.props.AdjustmentTimecardDucks.getAdjustmentTypesTimecards({id:timecardDetail.id});
  }

  handleChange(val) {
    const {
      router: { history },
    } = this.context;
    const {
      payrollAll,
      departmentPayroll,
      location: { pathname, query },
    } = this.props;

    let startDate;
    let endDate;
    let selectedPayroll = "";
    if (val === "custom" && query.payroll_id === "custom") return true;
    else if (val === "custom") {
      startDate = moment()
        .startOf("month")
        .format("YYYY-MM-DD");
      endDate = moment()
        .endOf("month")
        .format("YYYY-MM-DD");
      cookie.save("startDate", startDate, { path: "/" });
      cookie.save("endDate", endDate, { path: "/" });
      cookie.save("payrollId", "custom", { path: "/" });
      return history.push({
        pathname,
        search: queryString.stringify({
          ...query,
          start_date: startDate,
          end_date: endDate,
          payroll_id: val,
        }),
      });
    }

    selectedPayroll = payrollAll.find((i) => i.id === val);
    if (!selectedPayroll) {
      selectedPayroll = departmentPayroll.find((i) => i.id === val);
    }
    startDate = selectedPayroll.start_date;
    endDate = moment(selectedPayroll.end_date)
      .subtract(1, "day")
      .format("YYYY-MM-DD");
    cookie.save("startDate", startDate, { path: "/" });
    cookie.save("endDate", endDate, { path: "/" });
    cookie.save("payrollId", val, { path: "/" });
    return history.push({
      pathname,
      search: queryString.stringify({
        ...query,
        start_date: startDate,
        end_date: endDate,
        payroll_id: val,
      }),
    });
  }

  update(data) {
    const { isModalOpen } = this.state;
    const {
      location: { query },
      active,
    } = this.props;
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    const detail = {
      date: moment(data.date).format("YYYY-MM-DD"),
      employee_id: +query.employee_id,
      adjustments: data.adjustmentHour,
    };
    if (query.department_ids) {
      detail.department_ids = +query.department_ids;
    }
    if (query.team_id) {
      detail.team_id = +query.team_id;
    }
    return this.props.AdjustmentTimecardDucks.putAdjustmentTypesTimecards(
      detail
    )
      .then(() => {
        this.setState({ isModalOpen: !isModalOpen });
        toast.success("Adjustment Hours successfully added");
        this.props.DailyTimecardDucks.getDailyTimecardAdjustments({
          employee_id: query.employee_id,
          start_date: query.start_date,
          end_date: query.end_date,
        });
        if (active === "old") {

          let departmentIdsArray;
          let teamIdsArray;
          if(query?.department_ids){
           
            if(Array.isArray(query?.department_ids)){
              departmentIdsArray= query?.department_ids;
            } 
            else{
              departmentIdsArray= [query?.department_ids]
            }
          }
          else{
            departmentIdsArray=[];
          }
          const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));
      
          if(query?.team_ids){
            if(Array.isArray(query?.team_ids)){
              teamIdsArray= query?.team_ids;
            } 
            else{
              teamIdsArray= [query?.team_ids]
            }
          }
          else{
            teamIdsArray=[];
          }
          const teamIdsIntegers = teamIdsArray.map(id => parseInt(id, 10));


          this.props.DailyTimecardDucks.getCustomDailyTimecardsV2({
            ...query,
            id: params.id,
            paginate: false,
            admin: true,
            department_ids: JSON.stringify(departmentIdsIntegers),
            team_ids: JSON.stringify(teamIdsIntegers),
          });
        } else {

          let departmentIdsArray;
          let teamIdsArray;
          if(query?.department_ids){
           
            if(Array.isArray(query?.department_ids)){
              departmentIdsArray= query?.department_ids;
            } 
            else{
              departmentIdsArray= [query?.department_ids]
            }
          }
          else{
            departmentIdsArray=[];
          }
          const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));
      
          if(query?.team_ids){
            if(Array.isArray(query?.team_ids)){
              teamIdsArray= query?.team_ids;
            } 
            else{
              teamIdsArray= [query?.team_ids]
            }
          }
          else{
            teamIdsArray=[];
          }
          const teamIdsIntegers = teamIdsArray.map(id => parseInt(id, 10));
          this.props.DailyTimecardDucks.getCustomDailyTimecards({
            ...query,
            id: params.id,
            paginate: false,
            admin: true,
            beta: "true",
            department_ids: JSON.stringify(departmentIdsIntegers),
            team_ids: JSON.stringify(teamIdsIntegers),
          });
        }
      })
      .catch((err) => {
        toast.error(err.daily_timecard_id);
        toast.error(err.daily_timecard);
        toast.error(err.total_hours);
        toast.error(err.adjustment_type);
        if (err.non_field_errors.length) {
          toast.error(err.non_field_errors[0]);
        }
      });
  }

  fetchInProgressActivityData() {
    const {
      location: { query },
    } = this.props;
    this.setState({ isActivityInProgressLoader: true });
    this.props.ActivityDucks.getInProgressActivity({
      employee_id: query.employee_id,
      start_date: query.start_date,
      end_date: query.end_date,
    })
      .then(() => {
        this.setState({ isActivityInProgressLoader: false });
        this.toggle();
      })
      .catch((err) => {
        this.setState({ isActivityInProgressLoader: false });
        this.toggle();
      });
  }

  toggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  goTo(url, data) {
    const {
      router: { history },
    } = this.context;
    const {
      location: { query, search, pathname },
    } = this.props;

    const payrollId = queryString.parse(search).payroll_id || "custom";
    const startDate = data.start_date
      ? moment(data.start_date).format("YYYY-MM-DD")
      : query.start_date;
    const endDate = data.end_date
      ? moment(data.end_date).format("YYYY-MM-DD")
      : query.end_date;
    history.push({
      pathname: url,
      search: queryString.stringify({
        parent: `${pathname}${search}`,
        start_date: startDate,
        end_date: endDate,
        employee_id: query.employee_id,
        employee: true,
        payroll_id: payrollId,
      }),
    });
  }

  clearNosql(empId){
    this.setState({ loadingClearNosql: true });
    this.props.DailyTimecardDucks.clearNosql({empId})
    .then(res => {
      toast.success("Nosql cleared successfully!!!");
      this.props.fetchData();
    })
    .catch(err => {
      const errorMessage = err?.errors?.details ?? "Something went wrong!!!";
      toast.error(errorMessage);
    })
    this.setState({loadingClearNosql: false});
  }

  render() {
    const {
      location,
      payrollAll,
      timecardDetail,
      adjustmentTypesList,
      dateFormat,
      adjustmentTimecardList,
      basicSettingDetail,
      departmentPayroll,
      toggleView,
      active,
      departmentAll,
      userExtraData,
      teamAll,
      setAttributeToggler,
      showHideIconToggle,
      showAllocateTasksPopup,
      isEmployeeCheck,
      userTimecardFilters,
      activityInProgressList,
      pathname,
      isLoading,
      toggleActivityInProgressSection,
      type
    } = this.props;
    const { isModalOpen ,currentIndex,employeeArrLength, loadingClearNosql} = this.state;
    const { query } = location;
    const {
      router: {
        route: {
          match: { params },
        }
      },
    } = this.context;
    let payrollOptions = [];
    let payrollList = [];
  
    if (query.department_ids && departmentPayroll && departmentPayroll.length) {
      payrollList = departmentPayroll;
    } else payrollList = payrollAll;

    payrollOptions =
      payrollList &&
      payrollList.map((item) => ({
        value: item.id,
        label: `${item.start_date_display} to ${item.end_date_display}`,
      }));
    payrollOptions.unshift({ value: "custom", label: "Custom Date Range" });
    let departmentOptions = [];
    let teamOptions = [];

    if (departmentAll && departmentAll.length) {
      const nullDeptExists = departmentAll.some(department => department.id === 0);
    
      if (nullDeptExists) {
        departmentAll.pop();
        departmentAll.splice(1, 0, { id: "true", name: "Null Department" });
      }
  
  
      departmentOptions =
        departmentAll &&
        departmentAll.map((item) => ({
          value: item.id,
          label: item.name,
        }));

      if (!userExtraData.department) {
        departmentOptions.splice(0, 0, { value: "", label: "All Departments" });
      }

      teamOptions =
        teamAll &&
        teamAll.map((item) => ({
          value: item.id,
          label: item.name,
        }));

      if (!userExtraData.team) {
        teamOptions.splice(0, 0, { value: "", label: "All Teams" });
      }
    }

    const adjustmentArray =
      adjustmentTypesList.length &&
      adjustmentTypesList.map((item) => ({
        ...item,
        adjustment_type: null,
        adjustment_type_id: item.id,
        daily_timecard: null,
        daily_timecard_id: null,
        double_over_time_hours: 0,
        double_over_time_mins: 0,
        id: null,
        over_time_hours: 0,
        over_time_mins: 0,
        regular_time_mins: 0,
        regular_time_hours: 0,
      }));
    const selectedPayroll = payrollList.length
      ? payrollList.find((i) => i.id === +query.payroll_id)
      : null;
    const historic = location.pathname.indexOf("historic") > -1;

    let dates = {};
    if (selectedPayroll) {
      dates = {
        end_date: selectedPayroll
          ? moment(selectedPayroll.end_datetime).format("YYYY-MM-DD")
          : null,
        start_date: selectedPayroll
          ? moment(selectedPayroll.start_datetime).format("YYYY-MM-DD")
          : null,
      };
    }

    return (
      <div>
      <Row className="filter ml-3 mr-4">
        <Col
          md={showAllocateTasksPopup ? 5 : 7}
          className={
            isEmployeeCheck && showAllocateTasksPopup
              ? "custom-column-width-large px-0"
              : "px-0"
          }
        >
          <section
            className={showAllocateTasksPopup ? "custom_dropdown" : null}
            style={{display:'flex',gap:"0.5rem"}}
          >
            {departmentAll && departmentAll?.length > 0 && (
              <div
                id="department"
                className="d-inline-block department-dropdown"
                style={{width:'12rem',
                  ...((this?.state?.selectedDepts?.length===1 ||this?.state?.selectedDepts?.length===0)&& query?.payroll_id !=='custom')&&{marginTop:'20px'}
                  }}
              >
                 <FormikMultiSelectAtom
                  key={this?.state?.selectedDepts?.join(',')}
                  id="department_id"
                  name="department_id"
                  items={departmentAll}
                  placeholder="Select Departments"
                  itemToString={(i) => i && i.name}
                  onChange={(data) => this.onDepartmentSelect(data)}
                  field={{value:this.state.selectedDepts}}

                 />
                <UncontrolledTooltip placement="top" target="department">
                  Select Department
                </UncontrolledTooltip>
              </div>
            )}

{departmentAll && departmentAll?.length > 0 && (
    
              <div id="team" className="d-inline-block department-dropdown" 
              style={{width:'12rem',
                ...((this?.state?.selectedDepts?.length===1 ||this?.state?.selectedDepts?.length===0)&& query?.payroll_id !=='custom')&&{marginTop:'20px'}
                }}>
                <FormikMultiSelectAtom
                 key={this?.state?.selectedTeams?.join(',')}
                 id="team_id"
                 name="team_id"
                 items={(this.dept_ids?.length === 0 || this.dept_ids === undefined)?[]:teamAll}
                 placeholder="Select Teams"
                 itemToString={(i) => i && i.name}
                 onChange={(data) => this.onTeamSelect(data)}
                 field={{value:this.state.selectedTeams}}
                 disabled={this.dept_ids?.length === 0 || this.dept_ids === undefined}
                />
                <UncontrolledTooltip placement="top" target="team">
                  Select Team
                </UncontrolledTooltip>
              </div>
)}

{departmentAll && departmentAll?.length > 0 && (

              <div id="submit" className="d-inline-block department-dropdown"
                style={{width:'12rem',
                  ...((this?.state?.selectedDepts?.length===1 ||this?.state?.selectedDepts?.length===0)&& query?.payroll_id !=='custom')&&{marginTop:'20px'}
                }}>
                 <Button
                   type="submit"
                   color="accent"
                   style={{ marginBottom: '24px'}}
                   className="pr-4 pl-4"
                   onClick={this.handleClick}
                 >
                   Apply
                </Button>
             </div>
)}
    
          </section>
        </Col>
        <Col
          md={showAllocateTasksPopup ? 7 : 5}
          className={
            isEmployeeCheck && showAllocateTasksPopup
              ? "px-0 custom-column-width-small"
              : "px-0"
          }
          style={selectedPayroll ? { paddingTop: "21px" } : {}}
        >
          {query.employee_id && !historic && (
            <div className="d-flex flex-row-reverse flex-wrap">
              {showHideIconToggle && (
                <section
                  className={
                    isEmployeeCheck && !showAllocateTasksPopup
                      ? "float-right mb-1"
                      : "float-right"
                  }
                >
                  <Button
                    onClick={() =>
                      setAttributeToggler(!this.props.attributeToggler)
                    }
                    color="accent"
                    className={`mr-0 ml-1`}
                  >
                    <div className={`${styles["button-icon"]}`} id="show_hide">
                      <img src={showHideIcon} alt="Icon" />
                      <UncontrolledTooltip placement="top" target="show_hide">
                        Show/Hide
                      </UncontrolledTooltip>
                    </div>
                  </Button>
                  <Button size='sm'  style={{marginLeft: '4px'}} onClick={()=>  this.setState({ toggleBroadcastMessage: true })}>Send Notification</Button>

                </section>
              )}
              <section
                className={
                  isEmployeeCheck && showAllocateTasksPopup
                    ? "custom-switch-decimal-layout float-right"
                    : "float-right"
                }
              >
                <span id="decimal-view">
                  <ButtonGroupAtom
                    options={DECIMAL_VIEW_OPTIONS}
                    active={
                      userTimecardFilters && userTimecardFilters.data
                        ? userTimecardFilters.data.timecard_decimal_view ==
                            "true" ||
                          userTimecardFilters.data.timecard_decimal_view == true
                          ? true
                          : false
                        : basicSettingDetail.timecard_decimal_view
                    }
                    onToggle={this.onDecimalHHMMToggle}
                  />
                </span>
                {activityInProgressList &&
                activityInProgressList.results.length &&
                !isLoading &&  activityInProgressList.nosql_data ?
                (
                  <section
                    className={
                      isEmployeeCheck && !showAllocateTasksPopup
                        ? "float-left "
                        : "float-left"
                    }
                  >
                    <Button
                      className={`mr-1 ml-1 mb-1 ${styles['nosql-button']}`}
                      color={'red'}
                      onClick={(e) => toggleActivityInProgressSection()}
                    >
                      <div
                        className={`${styles["button-icon"]}`}
                        id="activityProgress"
                      >
                        <img
                          src={InfoBlue1}
                          alt="Icon"
                          style={{ height: "20px", width: "20px" }}
                        />
                      </div>
                    </Button>
                    <UncontrolledTooltip
                      placement="top"
                      target="activityProgress"
                    >
                      Activity in progress and failed sessions
                    </UncontrolledTooltip>
                  </section>
                ) : null}

                <section
                  className={
                    isEmployeeCheck && showAllocateTasksPopup
                      ? "custom-switch-layout float-left"
                      : "float-left"
                  }
                  style={{ marginRight: "8px" }}
                >
                  <span id="switch-layout">
                    <ButtonGroupAtom
                      options={[
                        {
                          value: "new",
                          label: "Inline View",
                          icon: (
                            <img
                              src={ListEditIcon}
                              alt=""
                              width={20}
                              height={20}
                            />
                          ),
                        },
                        {
                          value: "old",
                          label: "List View",
                          icon: (
                            <img src={ListIcon} alt="" width={20} height={20} />
                          ),
                        },
                      ]}
                      active={active}
                      onToggle={(val) => toggleView(val)}
                    />
                  </span>
                  <UncontrolledTooltip placement="left" target="switch-layout">
                    Switch Layout
                  </UncontrolledTooltip>
                </section>
              </section>
            </div>
          )}
          {isModalOpen && (
            <AdjustmentHourFormModalComponent
              isOpen={isModalOpen}
              toggle={this.toggleModal}
              dateFormat={dateFormat}
              adjustmentTimecardList={adjustmentTimecardList}
              adjustmentTypesList={adjustmentTypesList}
              initialValues={{
                adjustmentHour: adjustmentArray,
                date: moment(query.start_date).toISOString(),
              }}
              update={this.update}
              timecardDetail={timecardDetail}
              location={location}
            />
          )}
        </Col>
        <Col className="px-0">
       
            <div
              className="timecard_payroll d-inline-block"
              style={showAllocateTasksPopup ? { marginTop: "10px",width:'12rem' } : {width:'12rem'}}
            >
              {selectedPayroll && (
                <div className="d-inline-flex">
                  <div className="font-12px">{selectedPayroll.name}</div>
                </div>
              )}
              <span id="payroll_date">
                <FilterDropdownComponent
                  paramKey="payroll_id"
                  location={location}
                  options={payrollOptions}
                  onChange={(val) => this.handleChange(val)}
                />
              </span>
              <UncontrolledTooltip placement="top" target="payroll_date">
                Selected Payroll
              </UncontrolledTooltip>
            </div>
  
          {/* {(query.payroll_id === "custom" || this.state?.selectedDepts?.length!==1) && (
            <div className="d-inline-block mt-2">
              <CustomTimecardFilterFormComponent
                location={location}
                dateFormat={dateFormat}
                initialValues={
                  query.start_date && query.end_date
                    ? {
                        end_date: moment(query.end_date).toISOString(),
                        start_date: moment(query.start_date).toISOString(),
                      }
                    : null
                }
              />
            </div>
          )} */}
          {active === "old" && (
            <section className="float-right dailyTimecard-filter pt-2 pl-2">
              <Button
                color="accent"
                onClick={() =>
                  this.goTo(`/timecard/custom/list/createtimecard`, dates)
                }
                className="float-right"
              >
                Add Missing Timecard
              </Button>
            </section>
          )}
          {query.employee_id && !historic && active === "old" && (
            <section className="float-right pl-2 pt-2">
              <Button
                color="accent"
                className="float-left mr-0"
                onClick={this.toggleModal}
              >
                Add Adjustment Hours &nbsp;
              </Button>
            </section>
          )}
          
            {userExtraData.company_permissions?.nosql && activityInProgressList?.clear_nosql  && <section className={`float-right dailyTimecard-filter pt-2 pl-2 `}>
              <Button
                color="warning"
                className={`float-right`}
                onClick={() => this.clearNosql(query.employee_id)}
                disabled={loadingClearNosql}
                >
                Clear Failed Shifts
              </Button>
            </section>}
           {type!=='Report' && <section className="float-right dailyTimecard-filter pt-2 pl-2">
              <Button
                color="accent"
                id='next_button'
                onClick={() =>
                  this.onPreviousAndNextClick('next')
                }
                className="float-right"
                disabled={currentIndex!==(employeeArrLength-1) && employeeArrLength>1 ?false:true}
              >
                Next
              </Button>
              <UncontrolledTooltip placement="top" target="next_button">
                  Next employee daily-timecard
              </UncontrolledTooltip>
            </section>
          }
          {type!=='Report' && 
            <section className="float-right pl-2 pt-2">
              <Button
                color="accent"
                id='previous_button'
                className="float-left mr-0"
                onClick={()=>this.onPreviousAndNextClick('previous')}
                disabled={currentIndex && employeeArrLength>1 ?false:true}
              >
                Previous &nbsp;
              </Button>
              <UncontrolledTooltip placement="top" target="previous_button">
                   Previous employee daily-timecard
              </UncontrolledTooltip>
            </section>
          }

        {this.state.toggleBroadcastMessage ? (
        <LayerAtom
          active={true}
          path={`/broadcast/message?access_token=${nre_token}&user_id=${userID}&employee__user_id=${+query.employee__user_id}&employee_id=${params.id}&companyId=${company_id||nre_company_id}&first_name=${query.first_name}&last_name=${query.last_name}&timecardsScreen=true`}
          setToggle={() => this.setState({toggleBroadcastMessage: false})}
         />
          ) : null}

          
        </Col>
      </Row>
      <Row>
      <Col md={6} className="mt-2 mb-3 " style={{ marginLeft: '1rem'}}>
       {(query.payroll_id === "custom") && (
            <div className="d-inline-block mt-2">
              <CustomTimecardFilterFormComponent
                location={location}
                dateFormat={dateFormat}
                initialValues={
                  query.start_date && query.end_date
                    ? {
                        end_date: moment(query.end_date).toISOString(),
                        start_date: moment(query.start_date).toISOString(),
                      }
                    : null
                }
              />
            </div>
          )}
          </Col>
      </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
  payrollAll: PayrollDucks.payrollAll(state),
  activityInProgressList: ActivityDucks.activityInProgressList(state),
  basicSettingDetail: BasicSettingDucks.basicSettingDetail(state),
  dailyTimecardList: DailyTimecardDucks.dailyTimecardList(state),
  dailyTimecardDetail: DailyTimecardDucks.dailyTimecardDetail(state),
  departmentAll: DepartmentDucks.departmentAll(state),
  userExtraData: UserDucks.userExtraData(state),
  teamAll: TeamDucks.teamAll(state),
  adjustmentTypesList: AdjustmentTypesDucks.adjustmentTypesList(state),
  dateFormat: BasicSettingDucks.dateFormat(state),
  timecardDetail: TimecardDucks.timecardDetail(state),
  departmentPayroll: PayrollDucks.departmentPayroll(state),
  userTimecardFilters: TimecardDucks.userTimecardFilters(state),
  timecardList: TimecardDucks.timecardList(state),
});

const mapActionsToProps = (dispatch) => ({
  ActivityDucks: bindActionCreators(ActivityDucks, dispatch),
  BasicSettingDucks: bindActionCreators(BasicSettingDucks, dispatch),
  TeamDucks: bindActionCreators(TeamDucks, dispatch),
  DepartmentDucks: bindActionCreators(DepartmentDucks, dispatch),
  AdjustmentTypesDucks: bindActionCreators(AdjustmentTypesDucks, dispatch),
  DailyTimecardDucks: bindActionCreators(DailyTimecardDucks, dispatch),
  AdjustmentTimecardDucks: bindActionCreators(
    AdjustmentTimecardDucks,
    dispatch
  ),
  TimecardDucks: bindActionCreators(TimecardDucks, dispatch),
});

DailyTimecardFilterSection.propTypes = {
  activityInProgressList: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  payrollAll: PropTypes.array.isRequired,
  departmentAll: PropTypes.object.isRequired,
  dateFormat: PropTypes.string.isRequired,
  toggleView: PropTypes.func,
};

DailyTimecardFilterSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(DailyTimecardFilterSection);
